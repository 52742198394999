<template>
    <div>
        <card-header title="Navigator Options" :subheader="trip.title" icon="fa-compass"/>

        <card-body>
            <card-list>

                <navigation-list-item
                    icon="fa-clipboard-list-check"
                    title="Trip Questions/Preferences"
                    :active="child && child.definition.component === 'questions'"
                    :badge="trip.question_count"
                    @click="$openCard('questions', {tripId: props.tripId}, card)"
                />
                <separator-list-item/>
                <task-navigation-list-item
                    title="Today Announcements"
                    icon="fa-bullhorn"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'today'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'today'}, card)"
                />
                <task-navigation-list-item
                    title="Travel Requirements"
                    icon="fa-tasks"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'travel'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'travel'}, card)"
                />
                <task-navigation-list-item
                    title="Tournament"
                    icon="fa-trophy"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'tournament'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'tournament'}, card)"
                />
                <task-navigation-list-item
                    title="Cities"
                    icon="fa-city"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'cities'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'cities'}, card)"
                />
                <task-navigation-list-item
                    title="Packing List"
                    icon="fa-suitcase-rolling"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'packing'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'packing'}, card)"
                />
                <task-navigation-list-item
                    title="FAQs"
                    icon="fa-question-square"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'faqs'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'faqs'}, card)"
                />
                <task-navigation-list-item
                    title="Coach Information"
                    icon="fa-tennis-ball"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'coach'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'coach'}, card)"
                />
                <task-navigation-list-item
                    title="Staff Information"
                    icon="fa-user"
                    :active="child && child.definition.component === 'trip-app-editor' && child.props.type === 'staff'"
                    @click="$openCard('trip-app-editor', {tripId:  props.tripId, type: 'staff'}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SearchListItem from "@/TIER/components/SearchListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import TripAddOnListItem from "@/components/TripAddOnListItem";
    import TaskNavigationListItem from "@/components/TaskNavigationListItem";

    export default {
        name: "TripAddOns",
        props: ['card', 'child', 'props'],
        components: {TaskNavigationListItem, TripAddOnListItem, CardBody, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                addons: [],
                trip: {}
            };
        },
        methods: {
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>
